
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






























































































.campaign-application {
  @include stage-layout;

  @apply tw-mt-0;

  &__container {
    &--success {
      min-height: 440px;

      @apply tw-items-center tw--mb-layout-4 tw-justify-center;

      .app-lottie-animation-cont {
        @apply tw-absolute tw-w-full tw-bottom-0 tw-left-0;
      }
    }
  }
}

.active-page-campaign-application {
  .app-banner {
    @apply tw-pt-layout-4;
  }
}
