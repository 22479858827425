
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "../theme-chalk/src/input-number.scss";

@include b(input-number) {
  @include m(small) {
    line-height: 38px;
  }

  @include when(controls-right) {
    @include e((increase, decrease)) {
      line-height: 19px;

      [class*="el-icon"] {
        transform: scale(0.9);
      }
    }

    &[class*="small"] {
      [class*="increase"],
      [class*="decrease"] {
        line-height: 19px;
      }
    }
  }
}
