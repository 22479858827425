
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        























































































































































































































































.questions-next-enter,
.questions-back-leave-to,
.survey-thank-you-enter {
  transform: translate3d(100vw, 0, 0);
}

.questions-next-enter-to,
.questions-back-enter-to,
.survey-thank-you-enter-to {
  transform: translate3d(0, 0, 0);
}

.questions-next-leave-to,
.questions-back-enter,
.survey-thank-you-leave-to {
  transform: translate3d(-100vw, 0, 0);
}

.nps-survey-form {
  padding: $--clb-layout-1 $--clb-mobile-padding $--clb-layout-2 $--clb-mobile-padding;
  margin: 0 auto;
  margin-top: -84px;
  max-width: 550px;
  min-height: calc(100vh - 10px);
  min-height: calc(100 * var(--vh, 1vh) - 10px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include sm-up {
    padding: 0 $--clb-space-6 $--clb-layout-3 $--clb-space-6;
    max-width: 800px;
  }

  .app-loader {
    position: relative;
    height: 90vh;
  }

  .morph {
    z-index: 0;
  }

  &__header,
  &__questions,
  &__thank-you {
    position: relative;
    transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 2;

    @include sm-up {
      transition: none;
      display: block;
    }
  }

  &__questions,
  &__thank-you {
    min-height: 60vh;
    display: flex;

    @include sm-up {
      margin-top: 0;
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-bottom: $--clb-layout-3;

    span {
      border-bottom: 2px solid $--clb-color-primary;
    }
  }

  &__section {
    transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    position: absolute;
    right: 10px;
    left: 10px;

    @include sm-up {
      transition: none;
      position: relative;
      right: 0;
      left: 0;

      &-desktop {
        position: relative;
        left: 0;
        top: 0;
      }
    }

    &-breadcrumb {
      font-size: $--clb-font-size-xs;
      font-weight: $--clb-font-weight__normal;
      margin: 0;

      @include sm-up {
        display: none;
      }
    }

    &-title {
      margin: $--clb-space-4 0 $--clb-space-2 0;
      font-size: $--clb-font-size-lg;

      @include md-up {
        font-size: $--clb-h3__font-size;
        margin: $--clb-space-6 0 $--clb-space-2 0;
      }
    }

    &-scale {
      font-size: $--clb-font-size-xs;
      font-weight: $--clb-font-weight__normal;
      display: flex;
      justify-content: space-between;

      @include sm-up {
        display: none;
      }
    }

    &-textarea {
      width: 100%;
      min-height: 100px;
      padding: $--clb-space-2;
      border: 1px solid $--clb-color-grey__grey-light;
      margin-top: $--clb-space-4;
      border-radius: $--clb-border-radius;
      background-color: $--clb-color-primary__white;

      &::placeholder {
        color: $--clb-color-grey__grey-light;
      }
    }
  }

  &__controls {
    margin-top: $--clb-space-4;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;

    label {
      margin: 0 0 $--clb-space-4 0;
      display: flex;
      font-size: $--clb-font-size-xs;

      @include sm-up {
        margin-bottom: $--clb-layout-2;
        font-size: $--clb-font-size-sm;
      }

      .el-checkbox {
        margin-right: $--clb-space-4;
      }
    }

    &-button {
      min-width: 163px;
    }
  }

  &__thank-you {
    padding: 0 $--clb-space-2;
    font-weight: $--clb-font-weight__normal;
    flex-direction: column;

    @include sm-up {
      margin-top: $--clb-space-6;

      p {
        max-width: 68ch;
      }
    }

    &-title {
      font-size: $--clb-h1__font-size;
      margin: 0;
      padding: 0;
    }

    &-lottie {
      z-index: 0;
      position: relative;
      left: -64px;
      top: -150px;

      @include sm-up {
        top: 0;
        left: 0;
      }
    }
  }
}

.back-link {
  display: block;
  align-items: center;
  background: $--jb-bg-accent-color;
  border: none;
  border-radius: 50%;
  color: $--clb-color-primary__white;
  font-size: 15px;
  height: 40px;
  width: 40px;
  justify-content: space-around;
  left: 40px;
  padding: $--jb-backlink-padding;
  position: absolute;
  text-decoration: none;
  top: 20px;
  transition: 0.3s ease;
  z-index: 99;

  .el-icon-back {
    position: absolute;
    height: 15px;
    width: 13px;
    display: inline-block;
    left: 13px;
    top: 12px;
  }

  &:hover {
    background: $--jb-light-primary-color;
    box-shadow: $--jb-box-shadow-base;
    color: $--jb-bg-primary-color;
    transform: scale(1.1);
  }

  @media (max-width: $--sm) {
    left: 16px;
    top: 50px;
  }

  @media (min-width: $--sm) {
    top: 95px;
  }
}
