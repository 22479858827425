
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        







































.app-card {
  width: 100%;
  border: none;

  &__hover {
    transition: $--clb-hover-transition;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 0.3s;

    &:hover {
      border: 1px solid $--clb-color-primary;
    }
  }

  .app-card__body {
    overflow: hidden;
    position: relative;
    border-radius: $--clb-border-radius $--clb-border-radius 0 0;

    &__hover {
      transition: $--clb-hover-transition;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .app-card__footer {
    position: relative;
  }
}

@media (min-width: $--sm) {
  .app-card {
    &__hover {
      will-change: transform;

      &:hover {
        transform: translateY(-6px) !important;
        cursor: pointer;
      }
    }
  }
}
