
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































































































































































.org-menu {
  .el-collapse {
    @apply tw-mx-space-4 tw-mb-space-2 tw-border-jb-grey-50 tw-border tw-border-solid tw-rounded;

    &-item {
      @apply tw-mb-space-2px;

      &__header {
        @apply tw-block tw-p-0 tw-m-space-2 tw-border-none tw-leading-3;

        .chevron-icon {
          @apply tw-transform tw--rotate-90 tw-duration-300;

          &.is-active {
            @apply tw-rotate-90;
          }
        }
      }

      &__content {
        @apply tw-p-0 tw-border-none;

        .app-search-list-v2 {
          @apply tw-pt-0;

          &__input {
            @apply tw-mx-space-2 tw--mt-space-2;
          }

          .el-scrollbar {
            @apply tw-shadow-none tw-rounded-none tw-h-40 tw-max-h-40;
          }

          .el-input--mini .el-input {
            &__inner {
              max-height: theme('spacing.layout-2');
              min-height: theme('spacing.layout-2');

              @apply tw-border-jb-grey-50;
            }
          }
        }
      }
    }
  }

  .el-collapse-item__wrap {
    border-bottom: theme('spacing.0');
    background: none;
  }
}
