
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        














































































































































.app-notification {
  border-radius: $--clb-border-radius;
  background: $--clb-color-primary__white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.12);
  width: 550px;
  max-width: 550px;

  &__title {
    background: $--clb-color-secondary__light;
    padding: $--clb-space-2;
    margin: 0;
    border-radius: $--clb-space-1;
  }

  .notification-list-item {
    border-bottom: 1px solid $--clb-border-color-base;
  }

  .app-search-list__wrapper {
    max-height: 500px !important;
  }

  .app-search-list-v2 {
    &__entries-fixed-header,
    &__entries-group-header {
      line-height: $--clb-layout-2;
      margin: 0;
      padding: 0 $--clb-space-3;
      background-color: $--clb-color-grey__white-ter;
      color: $--clb-body-font;
      font-size: $--clb-font-size-xs;
      font-weight: 500;
      border-bottom: 1px solid $--clb-border-color-base;
    }
  }

  &__header,
  &__footer {
    background-color: $--clb-color-accent__dark;
    line-height: $--clb-layout-3;
    text-align: right;
    padding: 0 $--clb-space-3;
    color: $--clb-skeleton-color;

    a {
      color: $--clb-skeleton-color !important;
      padding: 0;
      font-size: $--clb-font-size-xs;
      font-weight: 500;
      text-align: right;
      text-decoration: none;
    }

    a:hover {
      color: $--clb-color-primary__white !important;
      text-decoration: none;
    }
  }

  &__header {
    background-color: $--clb-color-primary;
    text-align: left;
    font-weight: 700;
    font-size: $--clb-font-size-base;
    padding: $--clb-space-2 $--clb-space-3;
  }

  &__footer {
    height: $--clb-layout-3 + 1px;
  }

  .scroll-to-load {
    background-color: theme('colors.bg-accent-color');

    @apply tw-h-layout-5 tw-flex tw-justify-center tw-items-center;
  }

  @media (max-width: $--sm) {
    width: calc(100vw - 32px);
  }

  @media (max-height: $--sm) {
    .app-search-list__wrapper {
      max-height: calc(100vh - #{$--clb-app-header-height + 100px}) !important;
      max-height: calc(100 * var(--vh, 1vh) - #{$--clb-app-header-height + 100px}) !important;
    }
  }
}
