
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






































































































.header {
  height: 80px;
  transition: height 0.5s ease-in-out;
  width: 100%;
  z-index: 13;

  &.no-flow {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.signout-link-container {
  line-height: 40px;
  text-align: right;
  flex: auto;

  a {
    color: $--tooltip-color;
    font-weight: 700;
    padding: 0 20px;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.logo-link {
  font-size: 0;

  .logo {
    color: $--jb-bg-primary-color;
    height: 40px;
    transition: color 500ms ease-out, transform 300ms ease-out;
    width: 40px;

    &:hover {
      color: #fdfdfd;
      transform: translate(-2px, -4px);
    }

    &.blue {
      color: $--jb-dark-primary-color;

      &:hover {
        color: $--jb-light-primary-color;
      }
    }
  }
}
