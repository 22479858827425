
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






































































































.app-user-dropdown-menu {
  &__user {
    @apply tw-bg-grey-selected tw-py-space-4;

    &__details {
      @apply tw-flex tw-flex-col tw-items-center tw-justify-center tw-whitespace-nowrap tw-overflow-hidden;

      .app-profile-picture {
        background-color: $--clb-color-secondary__dark;
        width: 60px;
        height: 60px;

        .el-avatar {
          @apply tw-text-h3 tw-font-medium;

          line-height: 44px;
        }
      }

      .user-name {
        @apply tw-text-jb-ink tw-capitalize tw-mt-space-2 tw-text-base tw-leading-none;
      }

      .user-email {
        @apply tw-text-jb-grey-700 tw-mt-space-1 tw-text-sm tw-truncate tw-leading-none;
      }
    }
  }

  &__footer {
    :first-child {
      @apply tw-border-t-0;
    }

    a {
      @apply tw-border-solid tw-border-t tw-border-jb-grey-50 hover:tw-bg-hover-background tw-text-sm tw-no-underline hover:tw-no-underline;
    }

    &-link {
      @apply tw-flex tw-items-center tw-h-9 tw-font-semibold tw-p-space-2;

      &-item {
        @apply tw-flex tw-w-full tw-items-center;

        .tag-new {
          @apply tw-bg-jb-teal tw-px-space-2 tw-pt-space-1 tw-pb-0.5 tw-mx-space-1 tw-align-middle tw-rounded-full
            tw-text-3xs tw-uppercase tw-font-bold tw-text-white;
        }

        .tag-soon {
          @apply tw-bg-jb-orange tw-px-space-2 tw-pt-space-1 tw-pb-0.5 tw-mx-space-1 tw-align-middle tw-rounded-full
            tw-text-3xs tw-uppercase tw-font-bold tw-text-white;
        }

        &.sign-out {
          @apply tw-justify-end;
        }

        .footer-icon {
          @apply tw-mr-space-3 tw-text-jb-grey-400;
        }

        .footer-text {
          @apply tw-text-jb-ink;
        }
      }
    }
  }
}
