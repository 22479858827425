
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        















































































.org-selection-view {
  h3 {
    text-align: center;
  }

  margin-top: 15vh;
  margin-top: calc(15 * var(--vh, 1vh));

  &__list {
    .app-search-list-v2 {
      &__input {
        .el-input--mini .el-input__inner {
          @apply tw-bg-white;
        }
      }
    }

    box-shadow: 0 4px 7px -5px $--clb-shadow-color;

    .app-search-list {
      height: 100%;

      &__wrapper {
        max-height: 60vh;
        max-height: calc(60 * var(--vh, 1vh));
      }
    }
  }

  @media (min-width: $--sm) {
    .org-selection-view {
      margin-top: 20vh;
      margin-top: calc(20 * var(--vh, 1vh));

      .app-search-list {
        &__wrapper {
          max-height: 40vh;
          max-height: calc(40 * var(--vh, 1vh));
        }
      }
    }
  }
}
