
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


























































































.notification-list-item {
  display: flex;
  flex-direction: row;
  padding: $--clb-space-2 $--clb-space-3 $--clb-space-3 $--clb-space-3;
  transition: background-color 0.2s ease-in;
  cursor: pointer;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    small {
      line-height: 22px;
    }

    &-notification {
      flex: 1;
      padding: 0 $--clb-space-3;
      border-left: 3px solid transparent;

      h4 {
        transition: color 0.2s ease-in;
        font-size: $--clb-font-size-base;
        margin: 0;
      }

      p {
        transition: color 0.2s ease-in;
        font-size: $--clb-font-size-sm;
      }

      &.urgent {
        border-left-color: $--clb-color-danger;
      }

      &.normal {
        border-left-color: $--clb-color-warning;
      }

      &.low {
        border-left-color: $--clb-color-primary;
      }
    }
  }

  &:hover,
  &.unseen {
    background: $--clb-color-secondary__light;
  }

  &:hover {
    .notification-list-item__content-notification {
      h4,
      p {
        color: $--clb-color-primary;
      }
    }
  }

  &__organizations {
    flex: 0 0 22px;

    li {
      margin-bottom: $--clb-space-1;
      outline: none;
    }

    .app-profile-picture {
      width: 22px;
      min-width: 22px;
      height: 22px;
      min-height: 22px;
      cursor: pointer;
    }

    .el-avatar {
      font-size: $--clb-font-size-xs - 2px;
      line-height: 40px !important;
    }
  }
}
