@import "../theme-chalk/src/select-dropdown.scss";

@include b(select-dropdown) {
  @apply tw-border-jb-grey-100;

  &.el-popper {
    .popper__arrow {
      @apply tw-hidden;
    }
  }

  &.el-popper[x-placement^=bottom] {
    @apply tw-mt-space-2px;
  }

  &.el-popper[x-placement^=top] {
    @apply tw-mb-space-2px;
  }

  @include when(multiple) {
    .el-select-dropdown__item {
      &.selected {
        background-color: #f3f5fd;
      }

      &.is-disabled:hover {
        color: $--color-text-placeholder;
      }
    }
  }
}

@media (max-width: $--sm) and (max-height: 800px) {
  @include b(select-dropdown__wrap) {
    max-height: $--select-dropdown-max-height / 5 * 3.5;
  }
}

@media (max-width: $--sm) and (max-height: 600px) {
  @include b(select-dropdown__wrap) {
    max-height: $--select-dropdown-max-height / 5 * 2.6;
  }
}
