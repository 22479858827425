
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


















































































































































.app-global-sidebar-list-accordion-item {
  @apply tw-relative;

  .el-collapse-item {
    &__header {
      @apply tw-text-base tw-leading-normal tw-h-auto tw-border-0 tw-text-jb-ink tw-block;
    }

    &__wrap {
      @apply tw-flex tw-flex-col tw-max-h-full tw-border-0;
    }

    &__content {
      @apply tw-flex tw-flex-col tw-max-h-full tw-overflow-y-auto tw-pb-space-1;
    }

    &__arrow {
      @apply tw-hidden;
    }
  }

  .el-scrollbar {
    &__wrap {
      @apply tw-overflow-x-hidden tw-overscroll-contain;

      margin-bottom: 0 !important;
    }

    &__bar.is-horizontal {
      @apply tw-hidden;
    }
  }

  .app-global-sidebar-list-accordion-item-scroll-overlay {
    --tw-gradient-to: rgba(255, 255, 255, 0) !important;

    @apply tw-pt-layout-6 tw-bg-gradient-to-t tw-from-white tw-to-transparent tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-origin-bottom tw-pointer-events-none;
  }
}
