@import "common/var";
@import "../theme-chalk/src/input.scss";

@include b(textarea) {
  user-select: auto !important;

  @include e(inner) {
    transition: $--border-transition-base, $--color-transition-base;

    &::placeholder {
      color: $--color-text-jb-placeholder;
    }

    @apply tw-border tw-border-jb-grey-100 tw-bg-white tw-text-jb-ink tw-shadow-none;

    &:hover:not(:focus) {
      @apply tw-border tw-border-jb-grey-100 tw-bg-grey-hover tw-text-jb-ink;
    }

    &:focus {
      @apply tw-border tw-border-jb-indigo tw-bg-white tw-text-jb-ink;

      &::placeholder {
        @apply tw-text-transparent;
      }
    }
  }
}

@include b(input) {
  user-select: auto !important;

  @include e(inner) {
    height: 60px;
    min-height: 60px;
    font-size: 18px;
    transition: $--border-transition-base, $--color-transition-base;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      @apply tw-text-jb-grey-400;
    }

    @apply tw-border tw-border-jb-grey-100 tw-bg-white tw-text-jb-ink tw-shadow-none;

    &:hover:not(:focus) {
      @apply tw-border tw-border-jb-grey-100 tw-bg-grey-hover tw-text-jb-ink;
    }

    &:focus {
      @apply tw-border tw-border-jb-indigo tw-bg-white tw-text-jb-ink;

      &::placeholder {
        @apply tw-text-transparent;
      }
    }
  }

  @include e(suffix) {
    font-size: $--jb-icon-checkmark-size;
    right: $--jb-input-icon-position;
  }

  @include m(prefix) {
    .el-textarea__inner,
    .el-input__inner {
      letter-spacing: $--input-letter-spacing;
      padding: $--input-inner-jb-padding;
      font-size: $--font-size-medium;
      height: $--input-jb-height;

      &:focus {
        padding: 31px 14px 18px;
      }
    }
  }

  @include m(small) {
    font-size: $--input-medium-font-size;

    @include e(inner) {
      min-height: 50px;
    }
  }

  @include m(mini) {
    @include e(inner) {
      min-height: 36px;
    }

    .el-textarea__inner,
    .el-input__inner {
      padding: 6px 12px;
      font-size: $--input-medium-font-size;
    }

    .el-input__icon {
      line-height: $--input-mini-height;
    }
  }
}

@include b(input-group) {
  @include e((append, prepend)) {
    border-color: transparent !important;
    transition: none;

    button.el-button,
    div.el-select .el-input__inner {
      transition: none;
      border-color: transparent;
      background-color: transparent;
      color: inherit;
      border: 1px solid $--border-color-base;
    }

    button.el-button:hover,
    div.el-select:hover .el-input__inner {
      border: 1px solid $--jb-dark-primary-color;
      color: $--clb-color-primary;
    }
  }

  @include e(append) {
    button.el-button,
    div.el-select .el-input__inner {
      border-radius: 0 $--clb-border-radius $--clb-border-radius 0;
      border-left-width: 0 !important;
    }

    button.el-button:hover,
    div.el-select:hover .el-input__inner {
      box-shadow: -1px 0 0 $--jb-dark-primary-color;
    }
  }

  @include e(prepend) {
    button.el-button,
    div.el-select .el-input__inner {
      border-radius: $--clb-border-radius 0 0 $--clb-border-radius;
      border-right-width: 0 !important;
    }

    button.el-button:hover,
    div.el-select:hover .el-input__inner {
      box-shadow: 1px 0 0 $--jb-dark-primary-color;
    }
  }
}
