@import "mixins/mixins";
@import "common/var";
@import "common/transition";

@include b(collapse) {
  border-top: 1px solid $--collapse-border-color;
  border-bottom: 1px solid $--collapse-border-color;
}
@include b(collapse-item) {
  @include when(disabled) {
    .el-collapse-item__header {
      color: $--font-color-disabled-base;
      cursor: not-allowed;
    }
  }
  @include e(header) {
    display: flex;
    align-items: center;
    height: $--collapse-header-height;
    line-height: $--collapse-header-height;
    background-color: $--collapse-header-background-color;
    color: $--collapse-header-font-color;
    cursor: pointer;
    border-bottom: 1px solid $--collapse-border-color;
    font-size: $--collapse-header-font-size;
    font-weight: 500;
    transition: border-bottom-color .3s;
    outline: none;
    @include e(arrow) {
      margin: 0 8px 0 auto;
      transition: transform .3s;
      font-weight: 300;
      @include when(active) {
        transform: rotate(90deg);
      }
    }
    &.focusing:focus:not(:hover){
      color: $--color-primary;
    }
    @include when(active) {
      border-bottom-color: transparent;
    }
  }

  @include e(wrap) {
    will-change: height;
    background-color: $--collapse-content-background-color;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom: 1px solid $--collapse-border-color;
  }

  @include e(content) {
    padding-bottom: 25px;
    font-size: $--collapse-content-font-size;
    color: $--collapse-content-font-color;
    line-height: 1.769230769230769;
  }

  &:last-child {
    margin-bottom: -1px;
  }
}
