
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































.app-right-sidebar {
  overflow-y: hidden;
  overflow-x: scroll;

  .el-drawer__body {
    margin-top: 90px;
  }

  &__header--portal {
    position: fixed;
    top: 59px;

    > * {
      border-bottom: 1px solid rgba(28, 24, 7, 0.1);
    }
  }

  .el-drawer {
    overflow-y: auto;
    max-width: $--clb-app-content-width + $--clb-layout-4 * 2;
    outline: transparent;
  }

  .el-drawer__header {
    width: 100%;
    max-width: $--clb-app-content-width + $--clb-layout-4 * 2;
    position: fixed;
    background: white;
    z-index: 1;

    .drawer-title {
      display: flex;
      justify-content: space-between;

      .close-icon {
        cursor: pointer;
        font-size: 24px;
        position: fixed;
        top: $--clb-space-4;
        right: $--clb-space-4;
        z-index: 1;
        float: right;
      }
    }
  }
}
