
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "../theme-chalk/src/progress.scss";

.progress-container {
  display: flex;
}

@include b(progress) {
  @include when(success) {
    .el-progress-bar__inner {
      background-color: #67c23a;
    }

    .el-progress__text {
      color: #67c23a;
    }
  }
}

@include b(progress-bar) {
  padding-right: 0;
  margin-right: 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  box-sizing: border-box;

  @include e(outer) {
    height: 0;
  }
}
