
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





























































.morph {
  position: fixed;
  overflow: visible;
  fill: #f3f5fd;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  min-height: 75vw;
  top: 0;
  left: -50vw;
  width: 200vw;
  z-index: -1;

  svg {
    width: 100%;
    max-width: 3456px;
    height: calc(100vh - 110px);
    min-height: 800px;
    margin: auto;
    padding: 55px 0 0 0;
    display: block;
    overflow: visible;
  }

  @media (min-width: $--lg) {
    width: 150vw;
    left: -25vw;
  }

  @media (min-width: 1600px) {
    width: 100vw;
    left: 0;
  }
}
