@import "mixins/mixins";
@import "common/var";

@include b(popper) {
  .popper__arrow,
  .popper__arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  .popper__arrow {
    border-width: $--popover-arrow-size;
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03))
  }

  .popper__arrow::after {
    content: " ";
    border-width: $--popover-arrow-size;
  }

  &[x-placement^="top"] {
    margin-bottom: #{$--popover-arrow-size + 6};
  }

  &[x-placement^="top"] .popper__arrow {
    bottom: -$--popover-arrow-size;
    left: 50%;
    margin-right: #{$--tooltip-arrow-size / 2};
    border-top-color: $--popover-border-color;
    border-bottom-width: 0;

    &::after {
      bottom: 1px;
      margin-left: -$--popover-arrow-size;
      border-top-color: $--popover-background-color;
      border-bottom-width: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: #{$--popover-arrow-size + 6};
  }

  &[x-placement^="bottom"] .popper__arrow {
    top: -$--popover-arrow-size;
    left: 50%;
    margin-right: #{$--tooltip-arrow-size / 2};
    border-top-width: 0;
    border-bottom-color: $--popover-border-color;

    &::after {
      top: 1px;
      margin-left: -$--popover-arrow-size;
      border-top-width: 0;
      border-bottom-color: $--popover-background-color;
    }
  }

  &[x-placement^="right"] {
    margin-left: #{$--popover-arrow-size + 6};
  }

  &[x-placement^="right"] .popper__arrow {
    top: 50%;
    left: -$--popover-arrow-size;
    margin-bottom: #{$--tooltip-arrow-size / 2};
    border-right-color: $--popover-border-color;
    border-left-width: 0;

    &::after {
      bottom: -$--popover-arrow-size;
      left: 1px;
      border-right-color: $--popover-background-color;
      border-left-width: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: #{$--popover-arrow-size + 6};
  }

  &[x-placement^="left"] .popper__arrow {
    top: 50%;
    right: -$--popover-arrow-size;
    margin-bottom: #{$--tooltip-arrow-size / 2};
    border-right-width: 0;
    border-left-color: $--popover-border-color;

    &::after {
      right: 1px;
      bottom: -$--popover-arrow-size;
      margin-left: -$--popover-arrow-size;
      border-right-width: 0;
      border-left-color: $--popover-background-color;
    }
  }
}