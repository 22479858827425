
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

















































































































































































































































































.campaign-layout {
  width: 100%;

  &__header {
    @media (min-width: 2200px) {
      @apply tw-max-w-screen-2xl tw-mx-auto;
      @apply tw-px-0 #{!important};
    }
  }

  .campaign-application-modal {
    .el-dialog__header {
      @apply tw-px-layout-1 tw-py-layout-2 md:tw-px-layout-3;
    }

    .el-dialog__title {
      @apply tw-text-jb-ink tw-font-semibold tw-text-xl;
    }

    .el-dialog__body {
      @apply tw-p-0;
    }
  }
}
