
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "mixins/mixins";
@import "common/var";

@include b(message) {
  min-width: $--jb-message-min-width;
  box-sizing: border-box;
  border-radius: $--border-radius-base;
  border-width: $--border-width-base;
  border-style: $--border-style-base;
  border-color: $--border-color-lighter;
  position: fixed;
  left: 50%;
  top: 77px;
  transform: translateX(-50%);
  background-color: $--message-background-color;
  transition: opacity 0.5s, transform 0.3s;
  overflow: hidden;
  padding: $--message-padding;
  display: flex;
  align-items: center;

  @include when(center) {
    justify-content: center;
  }

  @include when(closable) {
    .el-message__content {
      padding-right: 16px;
    }
  }

  p {
    margin: 0;
  }

  @include m(info) {
    .el-message__content {
      color: $--message-info-font-color;
    }
  }

  @include m(success) {
    background-color: $--color-success-lighter;
    border-color: $--color-success-light;

    .el-message__content {
      color: $--message-success-font-color;
    }
  }

  @include m(warning) {
    background-color: $--color-warning-lighter;
    border-color: $--color-warning-light;

    .el-message__content {
      color: $--message-warning-font-color;
    }
  }

  @include m(error) {
    background-color: $--color-danger-lighter;
    border-color: $--color-danger-light;

    .el-message__content {
      color: $--message-danger-font-color;
    }
  }

  @include e(icon) {
    margin-right: 10px;
  }

  @include e(content) {
    padding: 0;
    font-size: 14px;
    line-height: 1;

    &:focus {
      outline-width: 0;
    }
  }

  @include e(closeBtn) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: $--message-close-icon-color;
    font-size: $--message-close-size;

    &:focus {
      outline-width: 0;
    }

    &:hover {
      color: $--message-close-hover-color;
    }
  }

  .el-icon-success {
    color: $--message-success-font-color;
  }

  .el-icon-error {
    color: $--message-danger-font-color;
  }

  .el-icon-info {
    color: $--message-info-font-color;
  }

  .el-icon-warning {
    color: $--message-warning-font-color;
  }
}

.el-message-fade-enter,
.el-message-fade-leave-active {
  opacity: 0;
  transform: translate(-50%, -100%);
}

// MQ'S
@media (min-width: $--sm) {
  @include b(message) {
    top: 37px;
  }
}
