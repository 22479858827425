@import "../common/var";
@import "../mixins/mixins";
@import "./picker-panel.scss";

@include b(date-picker) {
  width: 322px;

  &.has-sidebar.has-time {
    width: 434px;
  }

  &.has-sidebar {
    width: 438px;
  }

  &.has-time .el-picker-panel__body-wrapper {
    position: relative;
  }

  .el-picker-panel__content {
    width: 292px;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  @include e(editor-wrap) {
    position: relative;
    display: table-cell;
    padding: 0 5px;
  }

  @include e(time-header) {
    position: relative;
    border-bottom: 1px solid $--datepicker-inner-border-color;
    font-size: 12px;
    padding: 8px 5px 5px 5px;
    display: table;
    width: 100%;
    box-sizing: border-box;
  }

  @include e(header) {
    margin: 12px;
    text-align: center;

    @include m(bordered) {
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: solid 1px $--border-color-lighter;

      & + .el-picker-panel__content {
        margin-top: 0;
      }
    }
  }

  @include e(header-label) {
    font-size: 16px;
    font-weight: 500;
    padding: 0 5px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    color: $--color-text-regular;

    &:hover {
      color: $--datepicker-hover-font-color;
    }

    &.active {
      color: $--datepicker-active-color;
    }
  }

  @include e(prev-btn) {
    float: left;
  }

  @include e(next-btn) {
    float: right;
  }

  @include e(time-wrap) {
    padding: 10px;
    text-align: center;
  }

  @include e(time-label) {
    float: left;
    cursor: pointer;
    line-height: 30px;
    margin-left: 10px;
  }
}
