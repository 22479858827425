
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        















































































































































































































































































































































.app-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: $--clb-app-header-height;
  background: $--clb-color-primary__white;

  @apply tw-border-b tw-border-solid tw-border-jb-grey-50 md:tw-border-b-0;

  &__nav {
    width: 100%;
    position: relative;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $--clb-app-header-height;
      margin: 0 $--clb-mobile-padding;

      @include xs-up {
        margin: 0 $--clb-mobile-padding;
      }

      @include sm-up {
        margin: 0 $--clb-space-6;
        height: $--clb-app-header-height;
      }
    }

    &-item {
      transition: margin $--clb-transition-time ease, padding $--clb-transition-time ease;
      display: flex;
      align-items: center;
      justify-content: center;

      @apply tw-ml-space-2 sm:tw-ml-space-4;

      &:first-child {
        @apply tw-ml-0;
      }
    }

    #campaign-search-list {
      .campaign-search-list-wrapper {
        width: calc(100% - 261px);
        max-width: 564px;
      }

      .app-search-list-v2 {
        &__input {
          @apply tw-mt-0;
        }

        .el-scrollbar {
          height: 100%;
          min-height: 300px;

          @apply tw-absolute tw-top-full tw-left-0 tw-right-0 tw-mt-space-2;
        }
      }
    }

    .el-popover__reference-wrapper {
      @apply tw-flex;
    }

    &-survey {
      padding: $--clb-space-4;
      width: 380px;
      min-width: 100%;
      max-width: calc(100vw - 10px);
    }

    &-notifications-list {
      border-right: $--clb-border-divider;

      .el-popover__reference-wrapper {
        display: inline;
        background: $--clb-color-grey__white-ter;
        border-radius: $--clb-border-radius;
        padding: $--clb-space-2;

        @apply tw-text-jb-grey-400 hover:tw-text-jb-indigo hover:tw-text-opacity-75 tw-ring-transparent tw-ring-2 hover:tw-ring-jb-indigo hover:tw-ring-opacity-25 tw-transition tw-rounded;
        @apply focus-within:tw-ring-opacity-100 focus-within:tw-text-jb-indigo focus-within:tw-ring-jb-indigo focus-within:tw-text-opacity-100 #{!important};
      }
    }

    &-notifications,
    &-notifications-list {
      font-size: 24px;
      margin-top: 1px;
      cursor: pointer;

      @apply tw-pr-space-2 sm:tw-pr-space-4;

      .el-badge__content--primary {
        background-color: $--clb-color-red-darker;
      }

      &--buttons {
        margin-top: $--clb-layout-1;
        display: flex;
        justify-content: flex-end;
      }

      &--light {
        color: #e6e6e6 !important;

        &:hover {
          color: #fff !important;
        }
      }

      .el-badge {
        margin-top: $--clb-space-1;
        user-select: none;
      }

      .el-icon-message-solid {
        transition: color 0.3s ease;

        &:hover,
        &.active {
          color: $--clb-color__headings;
        }
      }
    }
  }

  &.transparent {
    background: transparent;
  }

  .logotype {
    width: 160px;
    color: $--color-primary;
  }

  .hamburger {
    margin-right: $--clb-space-4;
  }

  .app-header_profilePicture {
    cursor: pointer;
    background-color: $--clb-color-secondary__dark;
  }

  &__portal {
    background-color: $--clb-color-primary__white;
    width: 100%;
    display: flex;
    align-self: flex-end;
    flex-direction: column;

    &--has-content {
      border-top: 1px solid rgba(28, 24, 7, 0.1);
    }

    > * {
      border-bottom: 1px solid rgba(28, 24, 7, 0.1);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .app-header__notification-icon {
    color: $--clb-color-pink !important;
  }
}

.el-popover.app-header__user-popover {
  width: 300px;
  padding: 0;
  border: none;

  .app-user-dropdown-menu {
    border-radius: $--clb-border-radius;
    overflow: hidden;
  }

  .popper__arrow::after {
    border-bottom-color: $--clb-color-primary;
  }

  &.popover-user-dropdown {
    @apply tw-mr-space-3;

    .popper__arrow::after {
      @apply tw-border-grey-selected tw-border-b tw-border-none;
    }
  }
}

.el-popover.app-header__notifications-list {
  padding: 0;
  border: none;

  .app-notification {
    border-radius: $--clb-border-radius;
    overflow: hidden;
  }

  .popper__arrow::after {
    border-bottom-color: $--clb-color-primary !important;
  }

  .app-search-list__wrapper {
    max-height: 260px;
  }
}

@media (max-width: 310px) {
  .el-popover.app-header__user-popover {
    width: calc(100vw - 10px);
  }
}

@media (min-width: $--md) {
  .app-header {
    &__portal {
      position: relative;
      width: calc(100% - #{$--clb-app-left-sidebar-width});
    }

    &.is-advertiser {
      .app-header__portal {
        width: 100%;
      }
    }
  }
}

.app-header__nav-payment-popper {
  top: 40px !important;
  padding: 0 !important;

  .app-header-payment-cont {
    @apply tw-flex tw-flex-col;

    .app-text {
      @apply tw-text-jb-ink tw-cursor-pointer tw-p-space-2 hover:tw-bg-grey-hover tw-font-semibold;

      &:hover {
        @apply tw-font-medium;
      }
    }
  }
}
