
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




























































































































































.campaign-navigation-header {
  .el-tabs {
    &__header {
      @apply tw-m-0;
    }

    &__nav-wrap::after {
      @apply tw-bg-transparent;
    }

    &__active-bar {
      @apply tw-h-space-4px tw-bg-jb-indigo;
    }

    &__item {
      @apply tw-text-jb-grey-700 hover:tw-text-jb-ink tw-px-layout-1;

      &::before {
        content: '';

        @apply tw-w-0 tw-h-1/2 tw-absolute tw-top-1/4 tw-left-0 tw-border-0 tw-border-l tw-border-solid tw-border-jb-grey-100;
      }

      &.is-top,
      &.is-bottom {
        &:nth-child(2) {
          &::before {
            content: none;
          }
        }
      }

      &.is-active {
        @apply tw-text-jb-ink;
      }
    }
  }
}
