
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        











































.app-accordion {
  .el-collapse {
    border: 0;

    .hide-arrow {
      .el-collapse-item__arrow {
        display: none;
      }
    }
  }

  .el-collapse-item:last-child {
    margin-bottom: 0;
  }

  .el-collapse-item__header {
    color: $--clb-color__headings;
    font-size: $--clb-font-size-base;
    font-weight: $--clb-font-weight__semi-bold;
    line-height: $--clb-base__line-height;
    height: auto;
    padding: $--clb-space-5;
    border-radius: $--clb-border-radius;
    border-bottom-color: transparent;
  }

  .el-collapse-item__wrap {
    @apply tw-rounded tw-border-b-0;
  }

  .el-collapse-item__content {
    color: $--clb-color__headings;
    font-size: $--clb-font-size-sm;
    padding: 0 $--clb-space-5 $--clb-space-5 $--clb-space-5;
  }
}
