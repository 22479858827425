
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
























































































































































.campaign-application-form {
  @apply tw-w-full;

  h4 {
    @apply tw-mt-layout-2 tw-mx-0 tw-mb-layout-1;
  }

  &__left {
    p {
      @apply tw-text-sm md:tw-text-base;
    }
  }

  &__info {
    &__prohibited {
      li {
        @apply tw-leading-5;
      }
    }

    .comment-cont {
      text-align: center;

      textarea {
        min-height: 80px + ($--clb-space-2 * 2);
        border: 1px solid $--clb-border-color-base;
        transition: $--all-transition;

        @apply tw-w-full tw-p-space-2 tw-leading-5 tw-text-body-font tw-outline-none tw-rounded;

        @variants focus,hover {
          @apply tw-border-primary tw-text-jb-ink;
        }
      }
    }

    .slider-wrapper {
      @apply tw-mt-0 tw-mr-0 tw-mb-layout-2;
    }
  }

  &__restrictions {
    .el-checkbox {
      @apply tw-flex tw-cursor-pointer tw-whitespace-normal tw-mb-layout-1 ;
    }

    .app-button {
      @include clb-button;

      @apply tw-w-full;
    }
  }
}
