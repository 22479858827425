
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "../theme-chalk/src/date-picker.scss";

.el-date-editor {
  width: 100% !important;

  @apply tw-cursor-pointer;

  &.el-input__inner {
    &:hover:not(:focus) {
      @apply tw-bg-white;
    }
  }

  .el-range-separator {
    width: 26px;
    height: 35px;
    color: black;
  }

  .el-range-input {
    font-size: $--clb-font-size-xs;
  }
}

@media (max-width: $--sm) {
  .el-date-range-picker {
    width: 254px;
    overflow: hidden;
  }
}
