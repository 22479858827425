@import "../../theme/clb/reset/clb-reset";
@import "../../theme/clb/reset/clb-normalize";
@import "../../theme/clb/fonts/gilroy/index.scss";
@import "../../theme/clb/utilities/clb-mixins";
@import "../../theme/clb/utilities/clb-global-utilities";
@import "../../theme/clb/common/clb-var";

.theme-clb {
  body {
    font-family: "Gilroy", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: $--clb-font-size-base;
    color: $--clb-body-font;
    background-color: $--clb-color-primary__white;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    padding: 0 !important;
    margin: 0;
    -webkit-overflow-scrolling: touch;

    ::selection {
      background-color: #e3e7f8;
      color: $--clb-color-primary;
    }
  }

  a {
    @include linkStates(
      $--clb-link-color,
      $--clb-visited,
      $--clb-hover,
      $--clb-active
    );

    font-weight: 700;
    text-decoration: none;
  }

  p {
    font-size: $--clb-font-size-base;
    line-height: 20px;

    @include dont-break-out();
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $--clb-color-primary__black;
  }

  // ======== HEADERS =========
  h1 {
    font-size: $--clb-h1__font-size;
    font-weight: $--clb-font-weight__bold;
    color: $--clb-color__headings;
    line-height: $--clb-h1__line-height;
  }

  h2 {
    font-size: $--clb-h2__font-size;
    font-weight: $--clb-font-weight__bold;
    color: $--clb-color__headings;
  }

  h3 {
    font-size: $--clb-h3__font-size;
    font-weight: $--clb-font-weight__bold;
    color: $--clb-color__headings;
  }

  h4 {
    font-size: $--clb-h4__font-size;
  }

  h4,
  h5,
  h6 {
    font-size: inherit;
  }

  .desktop-inner-wrapper {
    max-width: $--clb-app-content-width;
    margin: 0 auto;
    position: relative;
    padding: 0 $--clb-layout-4;
  }

  .vue-slider {
    padding: 12px 8px 40px 0 !important;

    .vue-slider-rail {
      background: $--clb-disabled-color;
      cursor: pointer;

      .vue-slider-mark-step {
        width: 1px;
        height: 8px;
        left: 50%;
        top: 20px;
        border-radius: 0;
      }

      .vue-slider-piecewise-dot {
        background: #cfcfcf;
        border-radius: 0;
        height: 95%;
        width: 39%;
        visibility: visible !important;
      }
    }

    &.vue-slider-ltr {
      .vue-slider-mark-label {
        margin-top: 20px;
      }

      .vue-slider-process {
        background: $--clb-color-primary;
      }
    }

    .vue-slider-dot-tooltip-inner {
      border-color: $--clb-color-primary;
      background-color: $--clb-color-primary__dark;
      line-height: 32px;
      padding: 4px 16px;
      font-size: 16px;
      font-weight: $--clb-font-weight__semi-bold;

      @media (max-width: $--sm) {
        padding: 4px 8px;
        line-height: 28px;
        font-size: $--clb-font-size-base - 2;
      }
    }
  }
}

.el-input--mini .el-input__inner {
  height: 28px;
  line-height: 28px;
}
