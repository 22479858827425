
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "../theme-chalk/src/tabs.scss";

@include b(tabs) {
  @include e(item) {
    &:focus.is-active.is-focus:not(:active) {
      box-shadow: none;
      border-radius: 0;
    }
  }

  @include m(card) {
    > .el-tabs__header .el-tabs__item .el-icon-close {
      line-height: 14px;
    }
  }
}

/* make scrollable and hide scrollbar */
.touch {
  .el-tabs__nav {
    transform: none !important;

    &-wrap {
      &.is-scrollable {
        padding: 0;
      }
    }

    &-scroll {
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: -25px;
      padding-bottom: 25px;
      overscroll-behavior-x: none;
    }

    &-prev,
    &-next {
      display: none;
    }
  }
}
