
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





































































































// FIXME: need some insane specificity to target el-input styles...
.el-form .app-form-input.is-error .el-input + .el-form-item__error {
  position: initial;
  display: inline-block;
  line-height: 1.5;
}
