
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




































































.org-selection-list {
  .app-search-list-v2 {
    &__entry {
      @apply tw-flex tw-items-center tw-py-space-2 tw-pl-space-3 hover:tw-bg-jb-grey-hover tw-cursor-pointer;

      .app-profile-picture {
        &__text {
          @apply tw-text-white tw-text-xs;
        }

        .el-avatar {
          @apply tw-bg-jb-grey-700;
        }
      }

      .names {
        text-align: left;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
      }

      &.disabled {
        padding: 0 24px;
        height: 87px;
        line-height: 87px;
      }

      &:hover {
        h4,
        h5 {
          color: $--clb-color-primary__black;
        }
      }
    }
  }

  /* IE SPECIFIC RULE */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow: auto;
    height: 280px;
  }
}
