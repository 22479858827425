
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        








































































































































































































.app-search-list-v2 {
  @apply tw-relative;

  &__input {
    @apply tw-relative;

    margin-top: 16px;

    .el-input--mini .el-input__inner {
      padding-left: theme('spacing.layout-3');

      @apply tw-pl-layout-3 tw-h-9 tw-border-transparent tw-text-sm tw-bg-jb-grey-50 tw-transition;

      &:focus {
        @apply tw-border-jb-indigo tw-bg-white;
      }
    }

    .search-icon {
      @apply tw-left-space-2 tw-w-space-4 tw-h-full tw-absolute tw-top-0 tw-text-jb-grey-400;
    }

    .cancel-icon {
      @apply tw-top-0 tw-right-space-4 tw-absolute tw-w-4 tw-h-full tw-text-jb-grey-400;
    }
  }

  &__entry {
    @apply tw-cursor-pointer tw-flex tw-p-layout-2 tw-m-0;

    transition: background-color 300ms ease-in-out;
  }

  .list-item-enter-active,
  .list-item-leave-active {
    transition: opacity 0.3s, transform 0.3s;
    transform-origin: left center;
  }

  .list-item-enter,
  .list-item-leave-to {
    opacity: 0;
  }

  .list-item-leave-active {
    transition: transform 0.3s;
    position: absolute;
    visibility: hidden;
    width: 100%;
  }

  .el-scrollbar {
    height: calc(100% - 53px);
    max-height: 340px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);

    &__wrap {
      overscroll-behavior-y: contain;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      margin-bottom: 0 !important;
    }

    @apply tw-top-space-1 tw-relative tw-bg-white tw-rounded;

    &__bar.is-horizontal {
      @apply tw-hidden;
    }

    .app-search-list-v2__entries {
      max-height: 340px;

      &-group {
        &-header {
          position: sticky;
          top: 0;
        }
      }
    }
  }

  &__wrapper {
    border-radius: 0 0 4px 4px;

    /* IE SPECIFIC RULE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @apply tw-overflow-visible;
    }
  }
}
