
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

@import "mixins/mixins";
@import "mixins/utils";
@import "common/var";
@import "select-dropdown";
@import "input";
@import "tag";
@import "option";
@import "option-group";
@import "scrollbar";

@include b(select) {
  width: 100%;
  display: inline-block;
  position: relative;

  .el-select__tags > span {
    display: flex;
    flex-wrap: wrap;
  }

  .el-input__inner {
    cursor: pointer;
    padding-right: 35px;

    &:focus {
      &::placeholder {
        @apply tw-text-jb-grey-400;
      }
    }
  }

  .el-input {
    .el-select__caret {
      color: $--select-input-color;
      font-size: $--select-input-font-size;
      transition: transform 0.3s;
      transform: rotateZ(180deg);
      cursor: pointer;

      @include when(reverse) {
        transform: rotateZ(0deg);
      }

      @include when(show-close) {
        font-size: $--select-font-size;
        text-align: center;
        transform: rotateZ(180deg);
        color: $--select-input-color;
        transition: $--color-transition-base;

        @apply tw-rounded;

        &:hover {
          color: $--select-close-hover-color;
        }
      }
    }

    &.is-disabled {
      .el-input__inner,
      .el-select__caret {
        cursor: not-allowed !important;
      }
    }
  }

  > .el-input {
    display: block;
  }

  &:hover,
  &:focus {
    .el-input__inner {
      @apply tw-bg-hover-background;
    }
  }

  &:hover .el-input.is-disabled,
  &:focus .el-input.is-disabled {
    .el-input__inner {
      background-color: $--background-color-base;
    }
  }

  @include e(input) {
    border: none;
    outline: none;
    padding: 0;
    margin-left: 8px;
    font-size: $--select-font-size;
    appearance: none;
    height: 28px;
    background-color: transparent;

    @apply tw-text-jb-grey-400;

    @include when(mini) {
      height: 14px;
    }
  }

  @include e(close) {
    cursor: pointer;
    position: absolute;
    top: 8px;
    z-index: $--index-top;
    right: 25px;
    color: $--select-input-color;
    line-height: 18px;
    font-size: $--select-input-font-size;

    &:hover {
      @apply tw-bg-grey-hover;
    }
  }

  @include e(tags) {
    position: absolute;
    line-height: normal;
    white-space: normal;
    z-index: $--index-normal;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .el-tag__close {
    margin-top: -2px;
  }

  .el-tag,
  .el-tag.el-tag--info {
    box-sizing: border-box;

    @apply tw-rounded tw-bg-jb-grey-50 tw-ml-space-1 tw-h-auto tw-text-sm tw-text-jb-ink tw-px-2 tw-py-1 tw-border-none tw-flex tw-flex-row-reverse tw-font-medium;

    &__close.el-icon-close {
      right: -7px;
      top: 0;
      background: #fff;

      @apply tw-text-jb-grey-400 tw-bg-transparent tw--left-space-4px tw-text-h3 tw--top-space-1 tw-font-semi-bold;

      &:hover {
        background: none !important;
        color: #262626 !important;
      }

      &::before {
        display: block;
        transform: translate(0, 0.5px);
      }
    }
  }
}

.touch {
  @include b(select) {
    @include b(scrollbar__bar) {
      display: none;
    }
  }
}

.mw-550 {
  max-width: 550px;

  @media (max-width: $--sm) {
    max-width: calc(100% - 2.4em);
  }

  &.inline {
    position: relative !important;
    top: unset !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 16px 0 (-$--select-dropdown-max-height - 16px) 0;
    height: $--select-dropdown-max-height;
    z-index: 0 !important;

    .el-scrollbar {
      text-align: left;
      height: $--select-dropdown-max-height;
      max-height: $--select-dropdown-max-height;
      transition: max-height 200ms ease;
    }

    &.el-zoom-in-top-leave-active .el-scrollbar {
      display: none;
    }

    &.el-zoom-in-top-enter .el-scrollbar {
      max-height: 0;
    }

    .el-select-dropdown__empty {
      position: absolute;
      top: 0;
      padding: 0 20px;
      height: 34px;
      line-height: 34px;
      text-align: left;
    }

    .popper__arrow {
      z-index: -1;
    }

    @media (max-width: $--sm) and (max-height: 800px) {
      margin-bottom: (-($--select-dropdown-max-height / 5 * 3.5) - 16px);
      height: $--select-dropdown-max-height / 5 * 3.5;

      .el-scrollbar {
        max-height: $--select-dropdown-max-height / 5 * 3.5;
      }
    }

    @media (max-width: $--sm) and (max-height: 600px) {
      margin-bottom: (-($--select-dropdown-max-height / 5 * 2.6) - 15px);
      height: $--select-dropdown-max-height / 5 * 2.6;

      .el-scrollbar {
        max-height: $--select-dropdown-max-height / 5 * 2.6;
      }
    }
  }
}

.inline-select--mini {
  transition: min-height 300ms ease, margin 300ms ease;
  min-height: 40px;

  &.is_focus {
    min-height: 40px + ($--select-dropdown-max-height);
    margin-bottom: 0;

    @media (max-width: $--sm) and (max-height: 800px) {
      min-height: 40px + ($--select-dropdown-max-height / 5 * 3.5 + 16px);
    }

    @media (max-width: $--sm) and (max-height: 600px) {
      min-height: 40px + ($--select-dropdown-max-height / 5 * 2.6 + 16px);
    }
  }
}
