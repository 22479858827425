
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




















































.topbar.el-progress {
  z-index: 10000;
  position: fixed;
  width: 100%;

  .el-progress-bar {
    vertical-align: top !important;

    &__outer {
      border-radius: 0 !important;
      height: 5px !important;
      background-color: #fafafa;
    }

    &__inner {
      border-radius: 0 !important;
      height: 5px !important;
    }
  }
}
