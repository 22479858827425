
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "common/var";

.hidden {
  @each $break-point-name, $value in $--breakpoints-spec {
    &-#{$break-point-name} {
      @include res($break-point-name, $--breakpoints-spec) {
        display: none !important;
      }
    }
  }
}
