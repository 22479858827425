
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "../theme-chalk/src/popover.scss";

@include b(popover) {
  text-align: left;
  word-break: break-word;

  @include e(title) {
    font-weight: bold;
  }

  &.dark {
    background: #303e4f;
    border: 1px solid #303e4f;
    color: #e6e6e6;

    .el-popover__title {
      color: #fff;
    }

    &.el-popper {
      &[x-placement^="top"] .popper__arrow {
        border-top-color: #303e4f;

        &::after {
          border-top-color: #303e4f;
        }
      }

      &[x-placement^="bottom"] .popper__arrow {
        border-bottom-color: #303e4f;

        &::after {
          border-bottom-color: #303e4f;
        }
      }

      &[x-placement^="right"] .popper__arrow {
        border-right-color: #303e4f;

        &::after {
          border-right-color: #303e4f;
        }
      }

      &[x-placement^="left"] .popper__arrow {
        border-left-color: #303e4f;

        &::after {
          border-left-color: #303e4f;
        }
      }
    }
  }
}
