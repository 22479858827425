
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "common/var";
@import "../theme-chalk/src/button.scss";

@include b(button) {
  text-align: center;
  height: $--button-jb-height;
  transition: $--all-transition;
  position: relative;

  &.sortable-chosen,
  &.sortable-ghost {
    transition: none;
  }

  &.btn-100 {
    width: 100%;
  }

  &:hover {
    box-shadow: $--box-shadow-dark;
    transform: translateY(-3px);
    background: $--color-light;
    color: $--jb-light-primary-color;
    border-color: $--jb-light-primary-color;
  }

  &:active,
  &.active,
  &:focus {
    border-color: $--jb-dark-primary-color;
    background: $--color-light;
    outline: none;
    transform: translateY(0);
    color: $--jb-dark-primary-color;
  }

  &.is-plain:active {
    background: $--color-light;
  }

  @include m(small) {
    height: 36px;
  }

  @include m(mini) {
    height: 32px;
  }

  @include m(primary) {
    &:hover {
      background: $--jb-light-primary-color;
      color: #fff;
      border-color: $--jb-light-primary-color;
    }

    &:active,
    &.active,
    &:focus {
      border-color: $--jb-dark-primary-color;
      background: $--jb-dark-primary-color;
      color: #fff;
    }
  }
}

@include b(button-group) {
  > .el-button {
    &.is-disabled {
      z-index: 0;
    }

    &:hover {
      z-index: 2;
    }
  }
}

//Next button
.next-button {
  background-color: $--jb-dark-primary-color;
  color: $--color-white;
  font-size: $--button-font-size;
  border: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: 0;
  margin: 8px 0 32px 0;

  @extend .btn-100;

  &:hover,
  &:focus {
    color: #fff;
    background: $--jb-light-primary-color;
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.18);
  }

  &:active {
    background: $--jb-dark-primary-color;
  }
}

.show-password-button {
  position: relative;
  bottom: -13px;
  margin-bottom: 0;
}
