
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "var";
@import "../../theme-chalk/src/common/transition";

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.2s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-leave-active {
  transition-duration: 0.1s;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}

.slide-up-enter,
.slide-up-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translate(0, -100%);
}
