
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        















































































svg.skeleton-box {
  margin: auto;
  border-radius: $--clb-border-radius;
  display: block;
  background: $--clb-skeleton-color;

  #mask {
    animation: mask 2s ease infinite;
  }

  @keyframes mask {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(280px);
    }
  }
}
