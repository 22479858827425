@import "../../theme/jumbleberry/reset";

.touch body {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.theme-onboarding {
  #app {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 0;
    min-height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));
  }

  ul,
  ol {
    padding: 0;
  }

  a {
    color: $--jb-black-primary-color;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .desktop-header-wrapper,
  .desktop-inner-wrapper {
    margin: 0 auto;
    position: relative;
  }

  .desktop-inner-wrapper {
    max-width: 1068px;
  }

  .el-form-item.inline-select {
    transition: min-height 300ms ease, margin 300ms ease;
    min-height: 60px;

    &.is_focus {
      min-height: 60px + ($--select-dropdown-max-height);
      margin-bottom: 0;

      @media (max-width: $--sm) and (max-height: 800px) {
        min-height: 60px + ($--select-dropdown-max-height / 5 * 3.5 + 32px);
      }

      @media (max-width: $--sm) and (max-height: 600px) {
        min-height: 60px + ($--select-dropdown-max-height / 5 * 2.6 + 32px);
      }
    }
  }
}
