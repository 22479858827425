@import "../common/var";

@include b(month-table) {
  font-size: 12px;
  margin: -1px;
  border-collapse: collapse;

  td {
    text-align: center;
    padding: 8px 0px;
    cursor: pointer;
    & div {
      height: 48px;
      padding: 6px 0;
      box-sizing: border-box;
    }
    &.today {
      .cell {
        color: $--color-primary;
        font-weight: bold;
      }
      &.start-date .cell,
      &.end-date .cell {
        color: $--color-white;
      }
    }

    &.disabled .cell {
      background-color: $--background-color-base;
      cursor: not-allowed;
      color: $--color-text-placeholder;

      &:hover {
        color: $--color-text-placeholder;
      }
    }

    .cell {
      width: 60px;
      height: 36px;
      display: block;
      line-height: 36px;
      color: $--datepicker-font-color;
      margin: 0 auto;
      border-radius: 18px;
      &:hover {
        color: $--datepicker-hover-font-color;
      }
    }

    &.in-range div {
      background-color: $--datepicker-inrange-background-color;
      &:hover {
        background-color: $--datepicker-inrange-hover-background-color;
      }
    }
    &.start-date div,
    &.end-date div {
      color: $--color-white;
    }

    &.start-date .cell,
    &.end-date .cell {
      color: $--color-white;
      background-color: $--datepicker-active-color;
    }

    &.start-date div {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    &.end-date div {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    &.current:not(.disabled) .cell {
      color: $--datepicker-active-color;
    }
  }
}
