
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


































































































































































































































#campaign-search-list {
  @apply tw-w-full tw-max-w-lg md:tw-max-w-full;

  @include md-up {
    width: theme('screens.mfw');
  }
}
