
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "common/var";
@import "../theme-chalk/src/card.scss";

@include b(card) {
  padding: $--card-main-jb-padding;
  border-radius: 9px;
  box-shadow: $--jb-box-shadow-base;

  @include e(body) {
    padding: 0;
  }

  @include e(header) {
    padding: #{$--card-padding - 2 $--card-padding};
    border-bottom: 1px solid $--card-border-color;
    box-sizing: border-box;
    margin-bottom: 23px;
  }
}
