
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
































.campaign-search-list-item-skeleton {
  display: flex;
  padding: $--clb-space-4 $--clb-space-3;
  flex-direction: column;
  border-bottom: 1px solid $--clb-border-color-lighter;

  .skeleton-box {
    margin: 0;
  }

  .campaign-search-list-item {
    &__body {
      margin: $--clb-space-1 + 2px $--clb-space-3 $--clb-space-5 0;
      font-size: $--clb-font-size-sm !important;
      line-height: 24px;
    }

    &__footer {
      display: flex;
      justify-content: space-between;

      &-item .skeleton-box {
        margin: 0;
      }
    }
  }
}
