
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
























































.app-iframe {
  position: relative;
  min-height: calc(100vh - #{$--clb-app-header-height * 3});
  width: 100%;

  .app-loader {
    border-radius: 0 0 $--clb-border-radius $--clb-border-radius;
  }

  iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0 0 $--clb-border-radius $--clb-border-radius;
  }
}
