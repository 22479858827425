
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


















































































.app-text {
  &--tag {
    &-span,
    &-div,
    &-sub,
    &-sup,
    &-label {
      line-height: $--clb-base__line-height;
    }

    &-p {
      line-height: $--clb-p__line-height;
    }

    &-h1 {
      line-height: $--clb-h1__line-height;
    }

    &-h2 {
      line-height: $--clb-h2__line-height;
    }

    &-h3 {
      line-height: $--clb-h3__line-height;
    }

    &-h4 {
      line-height: $--clb-h4__line-height;
    }

    &-h5 {
      line-height: $--clb-h5__line-height;
    }

    &-h6 {
      line-height: $--clb-h6__line-height;
    }
  }

  &--color {
    &-default {
      color: $--clb-body-font;
    }

    &-primary {
      color: $--clb-color-primary;
    }

    &-primary-light {
      color: $--clb-color-primary__light;
    }

    &-primary-dark {
      color: $--clb-color-primary__dark;
    }

    &-black {
      color: $--clb-color__headings;
    }

    &-white {
      color: $--clb-color-primary__white;
    }

    &-white-ter {
      color: $--clb-color-grey__white-ter;
    }

    &-grey-lighter {
      color: $--clb-color-grey__grey-lighter;
    }

    &-grey-light {
      color: $--clb-color-grey__grey-light;
    }

    &-grey {
      color: $--clb-color-grey__grey;
    }

    &-grey-dark {
      color: $--clb-color-grey__grey-dark;
    }

    &-grey-darker {
      color: $--clb-color-grey__grey-darker;
    }

    &-success {
      color: $--clb-color-success;
    }

    &-warning {
      color: $--clb-color-warning;
    }

    &-danger {
      color: $--clb-color-danger;
    }

    &-info {
      color: $--clb-color-info;
    }

    &-headings {
      color: $--clb-color__headings;
    }
  }

  &--size {
    &-xs {
      font-size: $--clb-font-size-xs;
    }

    &-sm {
      font-size: $--clb-font-size-sm;
    }

    &-md {
      font-size: $--clb-font-size-base;
    }

    &-lg {
      font-size: $--clb-font-size-lg;
    }

    &-xl {
      font-size: $--clb-font-size-xl;
    }

    &-h1,
    &-h2,
    &-h3,
    &-h4,
    &-h5,
    &-h6 {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    &-h1 {
      font-size: $--clb-h1__font-size;
      line-height: $--clb-h1__line-height;
    }

    &-h2 {
      font-size: $--clb-h2__font-size;
      line-height: $--clb-h2__line-height;
    }

    &-h3 {
      font-size: $--clb-h3__font-size;
      line-height: $--clb-h3__line-height;
    }

    &-h4 {
      font-size: $--clb-h4__font-size;
      line-height: $--clb-h4__line-height;
    }

    &-h5 {
      font-size: $--clb-h5__font-size;
      line-height: $--clb-h5__line-height;
    }

    &-h6 {
      font-size: $--clb-h6__font-size;
      line-height: $--clb-h6__line-height;
    }
  }

  &--weight {
    &-lighter {
      font-weight: $--clb-font-weight__lighter;
    }

    &-light {
      font-weight: $--clb-font-weight__light;
    }

    &-normal {
      font-weight: $--clb-font-weight__normal;
    }

    &-medium {
      font-weight: $--clb-font-weight__medium;
    }

    &-bold {
      font-weight: $--clb-font-weight__bold;
    }

    &-semi-bold {
      font-weight: $--clb-font-weight__semi-bold;
    }

    &-bolder {
      font-weight: $--clb-font-weight__bolder;
    }
  }

  &__icon-prepend {
    margin-right: $--clb-space-2;
  }

  &__icon-append {
    margin-left: $--clb-space-2;
  }
}
