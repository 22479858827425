
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



























































.app-number-picker {
  padding: 0;
  display: flex;
  justify-content: space-evenly;
}

.number {
  padding: $--clb-layout-2 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  min-width: 1.25rem;
  text-align: center;
  color: $--clb-color-grey__grey-darker;
  cursor: pointer;
  transition: font-size 100ms linear, color 100ms linear;
  display: inline-block;

  @include md-up {
    padding: $--clb-layout-3 0;
  }

  &:hover {
    font-size: 1.75rem;

    @include md-up {
      font-size: 2.5rem;
    }
  }

  .active > & {
    color: $--clb-color-grey__grey-lighter;

    &.selected {
      font-size: 1.75rem;
      color: $--clb-color-primary__dark;

      @include md-up {
        font-size: 2.5rem;
      }
    }
  }

  :hover > & {
    color: $--clb-color-grey__grey-darker;
  }
}
