
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





































































































.app-button {
  @include clb-button;

  &.indigo {
    @apply tw-bg-jb-indigo hover:tw-bg-jb-indigo-dark;
  }
}
