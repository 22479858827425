
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "../theme-chalk/src/alert.scss";

@include b(alert) {
  @include e(closebtn) {
    cursor: pointer;

    @include when(customed) {
      top: 10px;
    }
  }
}
