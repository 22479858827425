
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "common/var";
@import "../theme-chalk/src/message-box.scss";

@include b(message-box) {
  @include e(message) {
    p {
      line-height: 20px;
    }
  }
}

@media (max-width: $--xs) {
  @include b(message-box) {
    width: calc(100% - #{$--clb-mobile-padding * 2});
    margin-left: $--clb-mobile-padding;
    margin-right: $--clb-mobile-padding;
  }
}
