@import "../common/var";

@include b(date-range-picker) {
  width: 646px;

  &.has-sidebar {
    width: 756px;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  .el-picker-panel__body {
    min-width: 513px;
  }

  .el-picker-panel__content {
    margin: 0;
  }

  @include e(header) {
    position: relative;
    text-align: center;
    height: 28px;

    [class*=arrow-left] {
      float: left;
    }

    [class*=arrow-right] {
      float: right;
    }

    div {
      font-size: 16px;
      font-weight: 500;
      margin-right: 50px;
    }
  }

  @include e(content) {
    float: left;
    width: 50%;
    box-sizing: border-box;
    margin: 0;
    padding: 16px;

    @include when(left) {
      border-right: 1px solid $--datepicker-inner-border-color;
    }
    .el-date-range-picker__header {

      div {
        margin-left: 50px;
        margin-right: 50px;
      }
    }
  }

  @include e(editors-wrap) {
    box-sizing: border-box;
    display: table-cell;

    @include when(right) {
      text-align: right;
    }
  }

  @include e(time-header) {
    position: relative;
    border-bottom: 1px solid $--datepicker-inner-border-color;
    font-size: 12px;
    padding: 8px 5px 5px 5px;
    display: table;
    width: 100%;
    box-sizing: border-box;

    > .el-icon-arrow-right {
      font-size: 20px;
      vertical-align: middle;
      display: table-cell;
      color: $--datepicker-icon-color;
    }
  }

  @include e(time-picker-wrap) {
    position: relative;
    display: table-cell;
    padding: 0 5px;

    .el-picker-panel {
      position: absolute;
      top: 13px;
      right: 0;
      z-index: 1;
      background: $--color-white;
    }
  }
}
