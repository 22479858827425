
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




















































































.app-survey-popup {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: $--clb-font-size-base !important;
    margin: 0 0 $--clb-space-3 0;
  }

  &__question {
    font-size: $--clb-font-size-sm !important;
    hyphens: none;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $--clb-space-4;

    @include xs-up {
      justify-content: space-between;
    }

    .app-button {
      min-width: 90px;
    }

    &-btn1 {
      @include xs-up {
        padding: 0;
        min-width: 0 !important;
      }
    }

    &-btn2 {
      margin-right: $--clb-space-2;
    }

    &-right {
      display: flex;
      justify-content: space-between;
    }
  }
}
