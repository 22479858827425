// @import "../theme-chalk/src/reset";
@import "common/var";
@import "./fonts/gilroy/index.scss";

html {
  min-height: 100vh;
  /* stylelint-disable */
  min-height: calc(100 * var(--vh, 1vh));
  /* stylelint-enable */
  width: 100%;
  overflow: visible;
}

body {
  font-family: "Gilroy", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: $--font-size-medium;
  color: $--color-black;
  -webkit-font-smoothing: antialiased;
  background: $--jb-bg-primary-color;
  min-height: 100vh;
  /* stylelint-disable */
  min-height: calc(100 * var(--vh, 1vh));
  /* stylelint-enable */
  padding: 0;
  overflow: auto;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}
