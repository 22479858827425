
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        













.app-banner {
  width: 100%;
  position: relative;
  overflow: hidden;

  &__content {
    max-width: 100vw;
    width: 100%;
    position: relative;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
