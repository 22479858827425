
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        









































































































































































































.main-layout {
  min-height: calc(100 * var(--vh, 1vh));
  transition: all $--clb-transition-time $--clb-transition-step;
  overflow-anchor: none;

  &__header {
    max-width: 100vw;
    width: 100%;
    z-index: 1992;
    position: fixed;
    top: 0;
  }

  &__right-sidebar {
    box-shadow: 0 2px 16px 0 rgba(0, 41, 132, 0.08);
  }

  &__left-sidebar,
  &__right-sidebar {
    height: 100%;
    position: fixed;
    z-index: $--clb-z-index-left-sidebar;

    .el-menu {
      width: $--clb-app-left-sidebar-width-collapsed;
    }

    .el-menu:not(.el-menu--collapse) {
      width: $--clb-app-left-sidebar-menu-width;
    }
  }

  &__content {
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));
    padding-top: $--clb-app-header-height;
    background: #f8f9fd;

    &-header {
      @apply tw-bg-white;
    }

    &-body {
      display: flex;
      flex: 1 1 auto;
      margin: 0 auto;
      width: 100%;

      > * {
        max-width: 100%;
        width: 100%;
        min-height: calc(100vh - #{$--clb-app-header-height});
        min-height: calc(100 * var(--vh, 1vh) - #{$--clb-app-header-height});
      }
    }

    &.fullWidth {
      .main-layout__content-body {
        max-width: 100% !important;
      }
    }
  }

  &__footer {
    max-width: 100vw;
    flex-shrink: 0;
  }

  &--no-header {
    .main-layout__content {
      padding-top: 0;
      min-height: 100vh;
      min-height: calc(100 * var(--vh, 1vh));
    }
  }
}

// MQ'S
@media (min-width: $--sm) {
  .main-layout {
    &__content {
      background: #f8f9fd;
    }
  }
}

@media (min-width: 992px) {
  .main-layout {
    &__content {
      min-height: calc(100vh - #{$--clb-app-header-height});
      min-height: calc(100 * var(--vh, 1vh) - #{$--clb-app-header-height});
      padding-top: 0;
    }

    &__wrapper {
      padding-top: #{$--clb-app-header-height};
    }

    &__left-sidebar,
    &__right-sidebar {
      height: calc(100% - 60px);
    }

    &--no-header {
      .main-layout__wrapper {
        padding-top: 0;
      }
    }
  }
}

@media (min-width: $--lg) {
  .main-layout {
    &__content {
      &-header {
        &-full {
          max-width: $--clb-app-content-width;
        }
      }

      &-body {
        &-full {
          max-width: $--clb-app-content-width;
        }
      }
    }
  }
}
