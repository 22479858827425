
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

















































































@import './assets/globalStyles/appV2.scss';
@import './assets/globalStyles/app.scss';

// Page fades in
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#app.loading {
  overflow: hidden;
  max-height: calc(100 * var(--vh, 1vh));
}

.jb-logo-cont {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 40px 40px 0 40px;
  transition: padding 300ms ease, width 300ms ease;

  @media (max-width: $--md) {
    padding: $--clb-mobile-padding $--clb-mobile-padding 0 $--clb-mobile-padding;
  }
}

#app > .app-loader {
  background-color: $--jb-bg-primary-color;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.logo-link {
  font-size: 0;

  .logo {
    color: $--jb-dark-primary-color;
    height: 40px;
    transition: color 500ms ease-out, transform 300ms ease-out;
    width: 40px;

    &:hover {
      transform: translate(-2px, -4px);
    }

    &.white {
      color: #fff;
    }

    &.blue {
      color: $--jb-dark-primary-color;

      &:hover {
        color: $--jb-light-primary-color;
      }
    }

    @media (max-width: $--md) {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }
}

.el-alert.app-requires-update {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  margin: 0;
  border-radius: 0;
}
