
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
@import "common/var";
@import "../theme-chalk/src/form.scss";

@include b(form) {
  position: relative;

  .el-button + .el-button {
    margin-left: 0;
  }

  @include b(form-item) {
    position: relative;
    margin-bottom: 12px;

    @include when(error) {
      .el-form-item__label + .el-form-item__content {
        @include e(error) {
          top: 100px;
        }
      }

      .el-input__inner,
      .el-textarea__inner {
        @apply tw-border tw-border-jb-red tw-bg-error-background tw-text-jb-ink hover:tw-bg-[#F4E9E6];
      }

      .el-input-group__append,
      .el-input-group__prepend {
        .el-input__inner {
          border-color: transparent;
        }
      }

      .el-input__validateIcon {
        color: $--color-danger;
      }

      .el-input--mini + .el-form-item__error {
        top: 40px !important;
      }

      .el-input--mini.el-textarea + .el-form-item__error {
        top: unset !important;
        bottom: -16px !important;
      }
    }

    @include when(success) {
      .el-input-group__append,
      .el-input-group__prepend {
        .el-input__inner {
          border-color: transparent;
        }
      }

      .el-input__validateIcon {
        color: $--color-success;
      }
    }

    @include e(error) {
      position: absolute;
      top: 100%;
      left: 0;
      line-height: 1;
      font-size: 14px;

      @apply tw-text-jb-red-dark;

      &.el-form-item__success {
        color: $--color-success;
      }
    }
  }
}
