
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        










































































































































































































































































































































































































































.moving {
  transition:
    opacity 0.3s ease,
    transform 0.3s cubic-bezier(0, 0, 0.2, 1),
    left 0.3s cubic-bezier(0, 0, 0.2, 1),
    padding 0.3s cubic-bezier(0, 0, 0.2, 1),
    width 0.3s cubic-bezier(0, 0, 0.2, 1),
    min-width 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.will-change {
  user-select: none !important;
  pointer-events: none !important;
}

.moving,
.will-change {
  > * {
    transition: none !important;
    will-change: none !important;
  }
}

.drawer-layout {
  position: relative;
  display: flex;
}

.drawer-wrap {
  position: absolute;
  transform: translateZ(0);
  height: 100%;
  visibility: hidden;
  display: flex;

  &.moving,
  &.will-change,
  &.active {
    visibility: visible;
  }

  &.will-change {
    will-change: transform;
  }
}

.drawer-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $--popup-modal-background-color;
  overscroll-behavior: contain;

  &.will-change {
    will-change: opacity, transform;
  }
}

.content-wrap {
  position: relative;
  width: 100%;
  box-shadow: 0 0 20px $--clb-shadow-color;

  &.will-change {
    will-change: transform;
  }
}

.header-wrap {
  position: fixed;
  transform: translateX(0);
  width: 100%;

  &.will-change {
    will-change: transform;
  }
}
