@import "mixins/mixins";
@import "common/var";
@import "common/popup";
@import "button";
@import "input";

@include b(message-box) {
  display: inline-block;
  width: $--msgbox-width;
  padding-bottom: 10px;
  vertical-align: middle;
  background-color: $--color-white;
  border-radius: $--msgbox-border-radius;
  border: 1px solid $--border-color-lighter;
  font-size: $--messagebox-font-size;
  box-shadow: $--box-shadow-light;
  text-align: left;
  overflow: hidden;
  backface-visibility: hidden;

  @include e(wrapper) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      width: 0;
      vertical-align: middle;
    }
  }

  @include e(header) {
    position: relative;
    padding: $--msgbox-padding-primary;
    padding-bottom: 10px;
  }

  @include e(title) {
    padding-left: 0;
    margin-bottom: 0;
    font-size: $--messagebox-font-size;
    line-height: 1;
    color: $--messagebox-title-color;
  }

  @include e(headerbtn) {
    position: absolute;
    top: $--msgbox-padding-primary;
    right: $--msgbox-padding-primary;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    font-size: $--message-close-size;
    cursor: pointer;

    .el-message-box__close {
      color: $--color-info;
    }

    &:focus, &:hover {
      .el-message-box__close {
        color: $--color-primary;
      }
    }

  }

  @include e(content) {
    padding: 10px $--msgbox-padding-primary;
    color: $--messagebox-content-color;
    font-size: $--messagebox-content-font-size;
  }
  
  @include e(container) {
    position: relative;
  }

  @include e(input) {
    padding-top: 15px;

    & input.invalid {
      border-color: $--color-danger;
      &:focus {
        border-color: $--color-danger;
      }
    }
  }

  @include e(status) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px !important;

    &::before {
      // 防止图标切割
      padding-left: 1px;
    }

    + .el-message-box__message {
      padding-left: 36px;
      padding-right: 12px;
    }

    &.el-icon-success {
      color: $--messagebox-success-color;
    }

    &.el-icon-info {
      color: $--messagebox-info-color;
    }

    &.el-icon-warning {
      color: $--messagebox-warning-color;
    }

    &.el-icon-error {
      color: $--messagebox-danger-color;
    }
  }

  @include e(message) {
    margin: 0;

    & p {
      margin: 0;
      line-height: 24px;
    }
  }

  @include e(errormsg) {
    color: $--color-danger;
    font-size: $--messagebox-error-font-size;
    min-height: 18px;
    margin-top: 2px;
  }

  @include e(btns) {
    padding: 5px 15px 0;
    text-align: right;

    & button:nth-child(2) {
      margin-left: 10px;
    }
  }

  @include e(btns-reverse) {
    flex-direction: row-reverse;
  }

  // centerAlign 布局
  @include m(center) {
    padding-bottom: 30px;

    @include e(header) {
      padding-top: 30px;
    }

    @include e(title) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include e(status) {
      position: relative;
      top: auto;
      padding-right: 5px;
      text-align: center;
      transform: translateY(-1px);
    }

    @include e(message) {
      margin-left: 0;
    }

    @include e((btns, content)) {
      text-align: center;
    }

    @include e(content) {
      $padding-horizontal: $--msgbox-padding-primary + 12px;

      padding-left: $padding-horizontal;
      padding-right: $padding-horizontal;
    }
  }
}

.msgbox-fade-enter-active {
  animation: msgbox-fade-in .3s;
}

.msgbox-fade-leave-active {
  animation: msgbox-fade-out .3s;
}

@keyframes msgbox-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes msgbox-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
