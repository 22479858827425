
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






































































































.docs-docs {
  transition: padding $--clb-transition-time ease;
  padding: $--clb-layout-1 $--clb-mobile-padding 0 $--clb-mobile-padding;
  border-radius: $--clb-border-radius;
  min-height: 100%;
  display: flex;

  @include md-up {
    transition: margin $--clb-transition-time ease, padding $--clb-transition-time ease;
    max-width: calc(100% - #{$--clb-mobile-padding * 2});
    margin: $--clb-layout-3 $--clb-mobile-padding 0 $--clb-mobile-padding;
    padding: $--clb-layout-1 $--clb-layout-2 0 $--clb-layout-2;
  }

  @include lg-up {
    margin-top: $--clb-layout-5;
    padding: $--clb-layout-1 $--clb-layout-4 0 $--clb-layout-4;
  }
}
