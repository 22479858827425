
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        












































.app-profile-picture {
  width: 36px;
  min-width: 36px;
  height: 36px;
  min-height: 36px;
  border-radius: 50%;
  background-color: $--clb-color-accent__light;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .app-profile-picture__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    width: 50px;
    height: 40px;
  }

  &__prepend-icon {
    position: absolute;
    left: -20px;
  }

  .el-avatar {
    background-color: transparent;
    line-height: 42px;
  }
}
