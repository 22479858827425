
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        








































@import '~@jumbleberry/el-tel-input/dist/elTelInput.css';

.app-country-icon {
  .wrapper {
    .el-flagged-label__icon {
      height: 26px;
    }

    /* fixes bug in safari: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    .globe {
      background: url('~@/assets/images/icons/globe-icon.png');
      background-size: 70%;
      height: 22px;

      @apply tw-w-layout-3 tw-mt-space-1 tw-bg-center tw-bg-no-repeat;
    }
  }
}
